import React from 'react';
import ReactDOM from 'react-dom';

/** Style */
import './css/App.css';

/** Components */
import App from './App';

ReactDOM.render(
   <React.StrictMode>
      <App />
   </React.StrictMode>,
   document.getElementById('root')
);
